import DripsHome from "../Views/DripsHome";
import DripsPaper from "../Views/DripsPaper";
import DripsGame from "../Views/DripsGame";
import DripsPoints from "../Views/DripsPoints";

const WebRoutes = [
  {
    component: <DripsHome />,
    path: "/",
  },
  {
    component: <DripsPaper />,
    path: "/drips-paper",
  },
  {
    component: <DripsGame />,
    path: "/drips-game",
  },
  {
    component: <DripsPoints />,
    path: "/drips-points",
  },
];

export default WebRoutes;