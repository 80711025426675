import { createTheme, ThemeProvider } from '@mui/material';
import Colors from './App/Assets/Colors';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Layout from './App/Layout';
import WebRoutes from './App/Routes/routes';
import "@fontsource/unbounded"
import "./App.css"

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white
    },
    secondary: {
      main: Colors.black,
      contrastText: Colors.white
    },
    text: {
      primary: Colors.white
    },
  },
  typography: {
    fontFamily: "unbounded, sans-sarif",
    h1: {
      fontSize: "72px",
    },
    h2: {
      fontSize: "64px"
    },
    h3: {
      fontSize: "52px"
    },
    h4: {
      fontSize: "40px"
    },
    h5: {
      fontSize: "24px"
    },
    h6: {
      fontSize: "18px"
    },
    body1: {
      fontSize: "16px"
    },
    body2: {
      fontSize: "14px"
    },
    caption: {
      fontSize: "12px"
    },
  }
})

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/' element={<Layout />}>
            {WebRoutes.map((route, ind) => (
              <Route key={ind} element={route.component} path={route.path} />
            ))}
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
