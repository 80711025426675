import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import Colors from '../../Assets/Colors'

function DripsPaper() {

  const dropShadowStyle = {
    filter: `drop-shadow(0px 2px 0px ${Colors.primary})`,
  };
  const textShadowStyle = {
    textShadow: `-1px -1px 0 ${Colors.primary}, 1px -1px 0 ${Colors.primary}, -1px 1px 0 ${Colors.primary}, 1px 1px 0 ${Colors.primary}`
  };
  const textStyle = {
    color: Colors.white
  }
  const dividerColor = {
    borderColor: Colors.white
  }

  return (
    <Box sx={{ backgroundColor: Colors.black }}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item md={8}>
            <Box
              sx={{
                color: Colors.black,
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                py: "50px"
              }}
            >
              <Typography
                variant='h2'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                Drips Vision
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Drips is no ordinary NFT collection —it's your all-access pass to joining a power-packed DAO destined to supercharge the Sui Ecosystem. Minting a Drips NFT plugs you into a squad of sharp, savvy investors, all pumped to push Sui towards the top of crypto
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Join a community of fellow Sui lovers. This isn’t just about owning another great piece of Sui art—it’s about building a community and treasury that is committed to being at the center of the action of the Sui ecosystem. As an NFT community, we are dedicated to building the biggest exclusive basket of Sui investments within our treasury and ensure our involvement in the rapid and unstoppable success and growth of the Sui ecosystem.
              </Typography>
              <Typography
                variant='h3'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                How It Unfolds:
              </Typography>
              <Typography
                variant='h5'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                Earn Your Spot:
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Want in on Drips? It’s the easiest and most fun Whitelist you’ll ever qualify for. All you need to do is step up and play the Drips Game—it's free to play, already live, and your ticket to the Drips mint whitelist.
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                But Sui's not just about being fast; it's about bringing everyone along for the ride. With a huge stash of 157 million SUI tokens just for making the ecosystem cooler and more welcoming, it's like throwing the biggest block party and everyone's invited. Plus, it's setting up a system where your voice counts—imagine voting on what game to play next, and actually getting to play it. That's the kind of community power Sui's all about.
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Climb the leaderboard by showcasing your skills and strategy. It's not just play; it's your first step to becoming a foundational member of the Drips DAO. Ready, set, game on!
              </Typography>
              <Divider sx={dividerColor} />
              <Typography
                variant='h4'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                Two Phases of Drips NFTs
              </Typography>
              <Typography
                variant='h5'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                Phase One - 333 Drips Counsel Mint
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                We kick off the revolution with our first mint of 333 ultra exclusive NFTs—each one an original piece of our old school OG Drips Art. Don’t let the simplicity of these cuties fool you, these NFTs are your golden ticket to the inner circle.
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Holders of these NFTs are part of the Drips Wise Counsel, the ones charting the course for Drip’s ascension. These NFTs give you the right to propose the direction of the Drips DAO. Any investment made by the Drips Treasury will first be proposed by a counsel holder and voted on by the rest of the counsel. If it passes it is then brought to the greater community to be voted on and passed.
              </Typography>
              <Divider sx={dividerColor} />
              <Typography
                variant='h5'
                sx={textShadowStyle}
                style={dropShadowStyle}
              >
                Phase Two - The Drips Community
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Drips then grows with our second mint of 3,333 NFTs, featuring jaw-dropping, 3D art that will rank among Sui sexiest NFTs. But these NFTs aren't just about looks—they pack a punch, giving you the power to vote on the proposals set forth by the Drips Counsel holders. It's democracy, degen-style, where every vote shapes the future of Drips, turning bold ideas into reality.
              </Typography>
              <Typography variant='body1' sx={textStyle}>
                Together all 3,666 Drip NFTs will decide the best path forward to build the Drips treasury into one of the largest Sui investment portfolios. Each Drip NFT holder will own a piece of the incredible value of the Sui ecosystem, which we all know is bound to be the most valuable in all of crypto.
              </Typography>
              <Divider sx={dividerColor} />
              <Typography variant='body1' sx={textStyle}>
                Your journey into the DRIP DAO is only a game away.<br />
                PLAY GAME
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default DripsPaper