import { Box, Container, Grid, Typography, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import Colors from '../../Assets/Colors'
import styled from '@emotion/styled';
import { useWallet } from '@suiet/wallet-kit';

const CustomTextField = styled(TextField)({
  '&:hover': {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Colors.primary, // Change this to your desired hover color
      },
    },
  },
});

function DripsPoints() {
  const [text, setText] = useState("");
  const wallet = useWallet();
  const dropShadowStyle = {
    filter: `drop-shadow(0px 2px 0px ${Colors.primary})`,
  };
  const textShadowStyle = {
    textShadow: `-1px -1px 0 ${Colors.primary}, 1px -1px 0 ${Colors.primary}, -1px 1px 0 ${Colors.primary}, 1px 1px 0 ${Colors.primary}`
  };

  return (
    <Box sx={{ backgroundColor: Colors.black, height: "100vh", display: "flex" }}>
      <Container>
        <Grid container justifyContent={"center"} height={"100%"} sx={{ overflow: "hidden" }}>
          <Grid item md={8}>
            <Box
              sx={{
                color: Colors.black,
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <Typography
                variant='h2'
                sx={{ ...textShadowStyle, fontSize: { md: "56px", sm: "42px", xs: "32px" } }}
                style={dropShadowStyle}
              >
                See Your Drips Points
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: { md: "nowrap", sm: "wrap", xs: "wrap" },
                  gap: "20px",
                }}
              >
                <CustomTextField
                  variant='outlined'
                  label={"Search by address"}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      color: Colors.primary,
                    }
                  }}
                  sx={{
                    width: { md: "60%", sm: "100%", xs: "100%" },
                    "fieldset": {
                      borderColor: Colors.primary,
                      borderWidth: "2px"
                    },
                  }}
                />
                <Box>
                  <Button
                    variant="outlined"
                    sx={{
                      borderWidth: "2px",
                      borderColor: Colors.primary,
                      color: Colors.white,
                      p: "14px 28px",
                      ":hover": {
                        borderWidth: "2px"
                      }
                    }}
                  >
                    Search
                  </Button>
                  {text !== "" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderWidth: "2px",
                        borderColor: Colors.primary,
                        color: Colors.white,
                        p: "14px 28px",
                        ":hover": {
                          borderWidth: "2px"
                        }
                      }}
                      onClick={() => setText("")}
                    >
                      Clear
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} height={"100%"}>
            <Box
              sx={{
                height: "100%",
                overflow: "hidden"
              }}
            >
              <iframe src={`https://talhaasif95.github.io/GotDripsLeaderboard?data=${wallet.address}`} width="100%" height="100%" frameborder="0"></iframe>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default DripsPoints