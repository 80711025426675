import React from "react";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import Images, { DiscordIcon } from "../../Assets/Images";
import Colors from "../../Assets/Colors";
import { useNavigate } from "react-router-dom";
import { Twitter } from "@mui/icons-material";

function DripsHome() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: Colors.black,
        height: { md: "100vh", sm: "100%", xs: "100%" },
        display: "flex",
      }}
    >
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
            alignItems: "center",
            flexWrap: { md: "nowrap", sm: "wrap-reverse", xs: "wrap-reverse" },
          }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                // mt: "50px"
              }}
            >
              <CardMedia
                component={"img"}
                src={Images.logo_name}
                sx={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: Colors.white,
                  }}
                >
                  Got Drips?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: Colors.white,
                  }}
                >
                  Just as a single drip of water can start a ripple effect,
                  imagine what 5,000 drips can do. Secure your whitelist spot
                  for the Drips DAO - NFT mint by playing the Drips game.
                  Compete to earn Drips points and make your mark on the
                  leaderboard.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: Colors.white,
                  }}
                >
                  Additional Prizes to be won including $SUI and Legendary 1:1
                  Drips NFTs.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
                  gap: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                    borderWidth: "2px",
                    color: Colors.white,
                    backgroundColor: Colors.primary,
                    fontWeight: "bold",
                    p: "14px 18px",
                    ":hover": {
                      borderWidth: "2px",
                    },
                  }}
                  onClick={() => navigate("/drips-game", { state: { fromHomePage: true } })}
                >
                  Play Drips Game
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                    borderWidth: "2px",
                    color: Colors.white,
                    backgroundColor: Colors.primary,
                    fontWeight: "bold",
                    p: "14px 18px",
                    ":hover": {
                      borderWidth: "2px",
                    },
                  }}
                  onClick={() => navigate("/drips-paper")}
                >
                  See Drips Paper
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: Colors.white }}>GET SOCIAL</Typography>
                <Box
                  sx={{
                    height: "2px",
                    width: "80px",
                    backgroundColor: Colors.white,
                  }}
                />
                <IconButton
                  onClick={() => window.open("https://twitter.com/DripsDAO")}
                >
                  <Twitter sx={{ color: Colors.primary, fontSize: "26px" }} />
                </IconButton>
                <IconButton
                  onClick={() => window.open("https://discord.gg/ZjWky8mGSM")}
                >
                  <DiscordIcon color={Colors.primary} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box>
              <CardMedia
                component={"img"}
                src={Images.image}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default DripsHome;
