import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Colors from '../../Assets/Colors'
import ReCAPTCHA from 'react-google-recaptcha';
import { ConnectButton } from '@suiet/wallet-kit';
import { useWallet, ConnectModal } from '@suiet/wallet-kit';
import "@suiet/wallet-kit/style.css";
// import "../../../App.css"

function DripsGame() {
  const [showModal, setShowModal] = useState(false)
  const wallet = useWallet();
  const { connected, disconnect } = wallet;

  // const [isVerified, setIsVerified] = useState(false);
  // const site_key = "6LcjTZgpAAAAAKRoE8poO-il4mdK4BV-DLPuC8gV";
  // const handleChange = () => {
  //   console.log("changed");
  //   setIsVerified(true);
  // }
  {/* <Box
        sx={{
          display: !isVerified ? "flex" : "none",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReCAPTCHA
          sitekey={site_key}
          onChange={handleChange}
          theme='dark'
          type="image"
        />
      </Box> */}

  useEffect(() => {
    if (connected) {
      return () => {
        disconnect();
      }
    };
  }, [connected]);

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: Colors.black,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <ConnectModal
        style={{
          zIndex: 999999
        }}
        open={showModal}
        onOpenChange={(open) => setShowModal(open)}
      >

      </ConnectModal>
      {connected && wallet.address ? (
        <iframe src={`https://talhaasif95.github.io/GotDrips/?data=${wallet.address}`} width="100%" height="100%" frameborder="0"></iframe>
      ) : (
        <ConnectButton label="Connect Wallet" />
      )}
    </Box >
  )
}

export default DripsGame