import { Box, Typography } from '@mui/material'
import React from 'react'
import Colors from '../../Assets/Colors';

function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "50px",
        backgroundColor: Colors.primary
      }}
    >
      <Typography variant="caption" sx={{ color: Colors.white }}>
        © 2024 - 2025 Drips. All rights unreserved.
      </Typography>
    </Box>
  )
}

export default Footer;