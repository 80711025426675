import {
  WaterDrop,
  DocumentScanner,
  StarBorderRounded,
  X,
} from "@mui/icons-material";
import { DiscordIcon } from "./App/Assets/Images";

const navigation = [
  {
    name: "Drips Paper",
    icon: <DocumentScanner />,
    path: "/drips-paper",
    type: "internal",
  },
  {
    name: "Drips Game",
    icon: <WaterDrop />,
    path: "/drips-game",
    type: "internal",
  },
  {
    name: "Drips Points",
    icon: <StarBorderRounded />,
    path: "/drips-points",
    type: "internal",
  },
  {
    name: "Twitter/X",
    icon: <X />,
    path: "https://twitter.com/DripsDAO",
    type: "external",
  },
  {
    name: "Discord",
    icon: <DiscordIcon />,
    path: "https://discord.gg/ZjWky8mGSM",
    type: "external",
  },
];

export default navigation;
