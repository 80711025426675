import React, { useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Images from '../../Assets/Images';
import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@mui/icons-material';
import Colors from '../../Assets/Colors';
import navigation from '../../../Navigation';
import { useNavigate } from 'react-router-dom';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  const navigateTo = (path) => {
    window.open(path, "_blank");
  };

  return (
    <Box
      component={"nav"}
      sx={{
        flexGrow: 1
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: Colors.black,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2,
            borderBottom: `1px solid ${Colors.primary}`,
            minHeight: "65px !important"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => navigate("/")}
          >
            <CardMedia
              component={"img"}
              src={Images.logo}
              sx={{
                width: "50px",
                height: "50px",
                objectFit: "contain"
              }}
            />
            <Typography sx={{ fontWeight: 700, fontSize: "18px", letterSpacing: "2px" }}>
              got drips?
            </Typography>
          </Box>
          <Box
            sx={{
              border: menuOpen ? `1px solid ${Colors.primary}` : "none",
              borderRadius: "6px",
              transition: "all .3s ease-in-out"
            }}
          >
            <Button
              sx={{
                minWidth: "40px !important",
                height: "40px",
                p: 1,
                transition: "all .3s ease-in-out",
                ":hover": {
                  backgroundColor: Colors.primary,
                  color: Colors.white
                }
              }}
              onClick={() => setMenuOpen(true)}
            >
              {menuOpen ? <Close /> : <MenuIcon />}
            </Button>
          </Box>
          <Menu
            open={menuOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => setMenuOpen(false)}
            slotProps={{
              paper: {
                sx: {
                  minWidth: "200px !important",
                  p: 2,
                  top: "65px !important",
                  borderRadius: "0px 0px 4px 4px",
                  boxShadow: `0px 2px 1px 2px ${Colors.primary}`,
                  backgroundColor: Colors.black,
                }
              }
            }}
          >
            {navigation.map((item, ind) => {
              if (item.type == "internal") {
                return (
                  <MenuItem
                    key={ind}
                    sx={{
                      p: 2,
                      ":hover": {
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        borderRadius: "6px"
                      },
                    }}
                    onClick={() => { navigate(item.path); setMenuOpen(false) }}
                  >
                    <ListItemIcon sx={{ color: Colors.white }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      {item.name}
                    </ListItemText>
                  </MenuItem>
                )
              } else if (item.type == "external") {
                return (
                  <MenuItem
                    key={ind}
                    sx={{
                      p: 2,
                      ":hover": {
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        borderRadius: "6px"
                      },
                    }}
                    onClick={() => navigateTo(item.path)}
                  >
                    <ListItemIcon sx={{ color: Colors.white }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      {item.name}
                    </ListItemText>
                  </MenuItem>
                )
              }
            })}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header;